import React from 'react';

function Footer() {
  return (
    <footer className="lg:container xl:container 2xl:container">
      <p className="p-2 text-center text-xs">Copyright © 2020 Montessori ICU. All Rights Reserved.</p>
    </footer>
  );
}

export default Footer;
